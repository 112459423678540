import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { RawOn, RawOff, Edit, Clear, ExpandMoreIcon, Article } from '@mui/icons-material';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Parse from '../vendor/Parse'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Textarea from '../components/Textarea';
const { useState } = React;
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StoryCard = ({ post, actions, preview, id, noAvatar }) => {

    return <Card>
        <CardHeader
            avatar={
                !noAvatar && <Avatar sx={{ bgcolor: preview ? "blue" : red[500] }} aria-label="recipe">
                    {post?.avatar ? post?.avatar : "A"}
                </Avatar>
            }
            action={actions}
            title={post?.rawTitle || ReactHtmlParser(post?.title)}
            subheader={post?.subheader}
        />

        {
            (post?.content || post?.rawContent) && <Typography variant="body2" color="text.secondary" style={{ padding: '16px' }}>
                {post?.rawContent || ReactHtmlParser(post?.content)}
            </Typography>
        }
        {
            "" && post?.image && <CardMedia
                component="img"
                height="294"
                image={post?.image}
                alt="Paella dish"
            />
        }
        {
            "" && post?.colorText && <Box
                display="flex"
                height="294px"
                margin="0 0 16px 0"
                justifyContent="center"
                alignItems={"center"}
                sx={{

                    backgroundColor: post?.colorText?.bgColor,
                    color: 'white',
                    fontSize: '2rem'
                }}
            >
                <Box sx={{ maxWidth: '80%' }}>
                    {ReactHtmlParser(post?.colorText?.content)}
                </Box>
            </Box>
        }
        {
            "" && post.video && <div class="youtube-video-container">
                <iframe width="560" height="315" src={post.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        }
    </Card>
}

export default function RecipeReviewCard({ list = [], isAuth, editPost, handleDelete, onPost, onClickItem, onEdit, ...props }) {

    const [expanded, setExpanded] = React.useState(false);
    const [newPostPayload, setNewPostPayload] = useState({})
    const [editingPost, setEditingPost] = useState({})
    const [editRawMode, setEditRawMode] = useState(false)
    const [colorText, setColorText] = useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            
            {
                list?.map((post, index) => <><Box id={`${post?.tag}`}></Box><StoryCard
                    {...props}
                    post={post}
                    actions={
                        <>
                            {
                                onClickItem && <IconButton aria-label="settings" onClick={() => onClickItem(post) }>
                                    <Article />
                                </IconButton>
                            }
                            {
                                isAuth && <>
                                    <IconButton aria-label="settings" onClick={() => handleDelete(post, index)}>
                                        <Clear />
                                    </IconButton>
                                    <IconButton aria-label="settings" onClick={() => {
                                        onEdit && onEdit(post)
                                    }}>
                                        <Edit />
                                    </IconButton>
                                </>
                            }
                        </>
                    }

                /></>)
            }
        </>

    );
}